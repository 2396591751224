.popup-overlay {
    position: fixed;
    top: 20px;
    left: 30%;
    width: 40%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .popup-content h2 {
    margin-bottom: 10px;
  }
  
  .popup-content button {
    margin-top: 20px;
  }