.dashboard {
    background-color: var(--panel-color);
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
   
}

.top {
    display: flex;
    top: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    padding: 10px 14px;
    background-color: var(--dark-color);
    z-index: 10;
}

.top .sidebarToggle {
    font-size: 26px;
    color: var(--text-color);
    cursor: pointer;

}


 .top .searchBox {
    position: relative;
    height: 35px;
    max-width: 250px;
    width: 100%;
    margin-left: 30px;
    margin-right: 180px;

}

.top .searchBox input {
    position: absolute;
    border: 1px solid var(--boarder-color);
    background-color: var(--panel-color);
    padding: 0 25px 0 50px;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    outline: none;
}

/* .top .searchBox i {
    position: relative;
    left: 80px;
    font-size: 22px;
    z-index: 10;
    top: 8%;
    transform: translateY(-50%);
    color: var(--text-color);
} */

.top .toDo {
    font-size: 26px;
    color: var(--text-color);
    cursor: pointer;

}

.top .notify {
    font-size: 26px;
    color: var(--text-color);
    cursor: pointer;

}

 .top .profile {
    color: var(--text-color);
    cursor: pointer;

}


.top .dropdown {
    /* float: right; */
   
    overflow: visible;
    position: relative;
   /* top: 10px; */
    /* left: 735px; */ 
   
}


.dropdown .dropbtn {
    position: fixed;
    top: 2rem;
    right: 23.5rem;
    font-size: 28px;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    padding: 6px 8px;
    background-color: inherit;
    margin: 0;
    border-radius: 10px;
    z-index: 999;
}

.logoutbtn{
    font-size: 16;
}




/* .dropDownContent {
    display: none;
    position: absolute;
    right: 3%;
    background-color: var(--panel-color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropDownPContent a {
    float: none;
    color: var(--text-color);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropDownPContent a:hover {
    background-color: var(--secondary-color);
}

.dropdown:hover .dropDownPContent {
    display: block;
} */


.listcontent{
   position: fixed;
    top: 4.5rem;
    right: 14rem;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    /* z-index: 100; */
    
}

.dropdown ul{
   list-style: none;
   padding: 0;
   
   
}
.dropdown li {
  border-bottom: 1px solid #00000057;
  cursor: pointer;
  
}

.listcontent a {
    float: none;
    color: var(--text-color);
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.listcontent i{
  
  font-size: 18px;
}
.listcontent span {
    /* margin-left: 10px; */
    padding: 0 25px 0 25px;
}



.dropdown li a:hover i,
.dropdown li a:hover span{
    color: var(--hover-color);
}

button{
    
            /* position: relative; */
    
            font-size: 28px;
            border: none;
            cursor: pointer;
            outline: none;
           
            padding: 6px 0px;
            background-color: inherit;
            margin: 0;
            border-radius: 10px;
            z-index: 999;
        
}

.userProfile{
    width: 28px;
    border-radius: 50% !important;
}

.notification-dot {
    position: fixed;
    top: 55px;
    right: 245px;
    /* bottom: 4px; */
    width: 7px;
    height: 7px;
    background-color: rgb(213, 0, 0);
    border-radius: 50%;
  }
  
  .todobtn{
    font-size: 28px;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    /* padding: 6px 8px; */
    background-color: var(--dark-color);
    /* background-color: inherit; */
    margin: 0;
    
}