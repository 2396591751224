@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
@import url("https://unicons.iconscout.com/release/v4.0.8/css/line.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root {

  /* COLORS */
  --primary-color: #005870;
  --secondary-color: #d1ad51;
  --panel-color: #F5EFF0;
  --text-color: #1D1923;
  --light-color: #71BDD6;
  --lighter-color: #a7dff3ab;
  --dark-color: #03495c67;
  --darker-color: #053e4ee3;
  --boarder-color: #3b484b;
  --hover-color: #e0a708;
  --titleicon-color: #005870;
  --box1-color: #24c8d1e8;
  --box2-color: #17c5a5c5;
  --box3-color: #f52c5bd0;

  /* TRANSIOTIONS */
  --tran-05: all 0.5s ease;
  --tran-03: all 0.3s ease;
  --tran-02: all 0.2s ease;

}

.full_content {
  width: 99.5%;
  height: 99.5vh;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2.5px;
  /* border: 1px solid var(--boarder-color); */
}

section {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

/* nav {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 6px 10px;
  border-right: 2px solid var(--boarder-color);
  transition: var(--tran-05);
  background-color: var(--dark-color);
  overflow-y: scroll;

  
}

nav.close {
  width: 73px;
}

nav .logo {
  display: flex;
  align-items: center;
}

nav .logoImage {
  display: flex;
  justify-content: center;
  min-width: 40px;

}

nav .logoImage img {
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

nav .logo .logoName {
  font-size: 20px;
  font-weight: 600;
  color: var(--darker-color);
  margin-left: 12px;
  transition: var(--tran-05);

}

nav.close .logoName {
  opacity: 0;
  pointer-events: none;
}

nav .menuItems {
  margin-top: 20px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuItems li {
  list-style: none;
}

.menuItems li a {
  display: flex;
  align-items: center;
  height: 50px;
  position: relative;
  text-decoration: none;

}


.menuItems li a i {
  font-size: 24px;
  min-width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);


}

.menuItems li a .linkName {
  font-size: 18px;
  font-weight: 400;
  transition: var(--tran-05);
  color: var(--text-color);
}

nav.close li a .linkName {
  opacity: 0;
  pointer-events: none;
}

.navLinks li a:hover i,
.navLinks li a:hover .linkName {
  color: var(--hover-color);
}
 */
 .dashboard {
  background-color: var(--panel-color);
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
}

/* .dashboard .top {
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  padding: 10px 14px;
  background-color: var(--dark-color);
  z-index: 10px;
} */

/* .dashboard .top .sidebarToggle {
  font-size: 26px;
  color: var(--text-color);
  cursor: pointer;

}

.dashboard .top .searchBox {
  position: relative;
  height: 35px;
  max-width: 600px;
  width: 100%;
  margin: 0 30px;

}

.top .searchBox input {
  position: absolute;
  border: 1px solid var(--boarder-color);
  background-color: var(--panel-color);
  padding: 0 25px 0 50px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 400;
  outline: none;
}

.top .searchBox i {
  position: absolute;
  left: 15px;
  font-size: 22px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-color);
}

.dashboard .top .toDo {
  font-size: 26px;
  color: var(--text-color);
  cursor: pointer;

}

.dashboard .top .notify {
  font-size: 26px;
  color: var(--text-color);
  cursor: pointer;

}

.dashboard .top .profile {
  color: var(--text-color);
  cursor: pointer;

} */


/* .top .dropdown {
  float: right;
  overflow: hidden;
}


.dropdown .dropbtn {
  font-size: 28px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  padding: 6px 8px;
  background-color: inherit;
  margin: 0;
  border-radius: 10px;
}


.dropdown:hover .dropbtn {
  background-color: var(--secondary-color);
}



.dropDownPContent {
  display: none;
  position: absolute;
  right: 3%;
  background-color: var(--panel-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropDownPContent a {
  float: none;
  color: var(--text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropDownPContent a:hover {
  background-color: var(--secondary-color);
}

.dropdown:hover .dropDownPContent {
  display: block;
}  */

.dashboard .dashContent {
  padding-top: 30px;

}

/* .dashContent .title {
  display: flex;
  align-items: center;
  margin: 20px 0 30px 0;

}

.dashContent .title i {
  position: relative;
  height: 35px;
  width: 35px;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: var(--hover-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.dashContent .title .text {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color);
  margin-left: 10px;
} */

.dashContent .boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 12px;
}

.dashContent .boxes .box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  width: calc(100% / 3 - 15px);
  padding: 10px 15px;
 
   transition: var(--tran-05); 

 }

.boxes .box .uil {
  font-size: 30px;
  color: var(--text-color);
}

.boxes .box .text {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
}

.boxes .box .number {
  font-size: 40px;
  font-weight: 500;
  color: var(--text-color);
}

.box .box1 {
  background-color: rebeccapurple;
}

.box .box2 {
  background-color: rebeccapurple;
}

.box .box3 {
  background-color: rebeccapurple;
}

.activity .title .textActivities {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  margin-left: 10px;
}   

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

@media (max-width: 1200px) {
  .dashboard .top .searchBox {
    position: relative;
    height: 35px;
    max-width: 200px;
    width: 100%;
    margin-left: 20px;
    margin-right: 130px;
  } 
     
}


@media (max-width: 1000px) {
  .full_content {
    border: none;
    background: none;
  }

  nav {
    width: 73px;
  }

  nav .logoName {
    opacity: 0;
    pointer-events: none;

  }

  nav.close .logoName {
    opacity: 0;
    pointer-events: none;
  }

  nav li a .linkName {
    opacity: 0;
    pointer-events: none;
  }

    .dashboard .top .searchBox {
      position: relative;
      height: 35px;
      max-width: 200px;
      width: 100%;
      margin-left: 20px;
      margin-right: 130px;
    }
}

@media (max-width: 750px) {
  .dashboard .boxes .box {
    width: calc(100% / 2 - 15px);
    margin-top: 7px;
    /* margin-right: 15px; */
  }
    .dashboard .top .searchBox {
      position: relative;
      height: 35px;
      max-width: 200px;
      width: 100%;
      margin-left: 20px;
      margin-right: 50px;
    }
}

@media (max-width: 600px) {
.dashContent .boxes .box {
    position: relative;
    width: 100%;
    
  }

    .dashboard .top .searchBox {
      position: relative;
      height: 35px;
      max-width: 175px;
      width: 100%;
      margin-left: 20px;
      margin-right: 10px;
      
    }
}


@media (max-width: 510px) {
  .full_content {
    border: none;
    background: none;
  }

  nav {
    width: 73px;
  }

  nav .logoName {
    opacity: 0;
    pointer-events: none;

  }

  /* nav.close .logoName {
            opacity: 0;
            pointer-events: none;
        } */

  nav li a .linkName {
    opacity: 0;
    pointer-events: none;
  }

  .dashboard .top .sidebarToggle {
    display: none;
  }

    .dashboard .top .searchBox {
      position: relative;
      height: 35px;
      max-width: 130px;
      width: 100%;
      margin-left: 20px;
      margin-right: 10px;
  
    }
}


/* mode */
/* .menuItems li a:hover:before{
    content: "";
    position: absolute;
    left: -7px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: goldenrod;
} */