@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} */
.fullcontent{
    width: 90%;
    height: 93vh;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    border: 2px solid #747474;
}
section{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
}
section .imgBx{
    position: relative;
    width: 50%;
    height: 100%;
}
section .imgBx img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    width: 45%;
    height: 60%;
}
section .contentBx{
    display: flex;
    justify-content: center;
    width: 50%;
    background: var(--dark-color);
}
section .contentBx .formBx{
    width: 70%;
    margin-top: 70px;
}
.link-button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
section .contentBx .formBx h2{
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1.5em;
    text-transform: uppercase;
    margin-bottom: 20px;
}
section .contentBx .formBx .inputBx{
    margin-bottom: 30px;
}
section .contentBx .formBx .inputBx span{
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
    color: var(--text-color);
    font-weight: 300;
    letter-spacing: 1px;
}
section .contentBx .formBx .inputBx input{
    width: 100%;
    padding: 12px 20px;
    outline: none;
    font-weight: 400;
    border: 1px solid #4b4a4a;
    background: transparent;
}
section .contentBx .formBx .inputBx select{
    width: 100%;
    padding: 12px 20px;
    outline: none;
    font-weight: 400;
    border: 1px solid #4b4a4a;
    background: transparent;
}
section .contentBx .formBx .inputBx input[type="submit"]{
    background: goldenrod;
    color: #0b366e9c;
    outline: none;
    border: none;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
}
section .contentBx .formBx .inputBx input[type="submit"]:hover{
    background: rgb(255, 183, 0);
}
section .contentBx .formBx .remember{
    margin-bottom: 10px;
    color: #525050;
    font-size: 14px;
    font-weight: 400;
}
section .contentBx .formBx .forget{
    float: right;
    font-size: 14px;
}
section .contentBx .formBx .forget button{
    text-decoration: underline;
    color: #525050;
    border: none;
    background: none;
}
section .contentBx .formBx .forget button:hover{
    text-decoration: underline;
    color: goldenrod;
    
}
.alert {
    background-color: white;
    height: 30px;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    border: 1.5px solid white;
  }
  .errorMsg{
    margin-left: 5px;
  }
  .alert-icon {
    background-color: goldenrod;
    border-radius: 4px;
    height: 14px;
    width: 15px;
    margin-top: 10px;
    color: white;
    stroke: white;
    stroke-width: 40;
    padding: 5px;
    margin-left: 2px;
  }
  
@media (max-width: 900px){
    .fullcontent{
        border: none;
        background: none;
    }
    section .imgBx{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    section .contentBx{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: none;
        z-index: 1;
    }
    h2{
        margin-left: 10px;
    }
    section .contentBx .formBx{
        width: 50%;
        height: 100%;
        padding: 10px;
        background: rgb(255 255 255 / 0.8);
        align-items: center;
        border: 2px solid #747474;
    }
    section .contentBx .formBx .inputBx{
        width: 90%;
        height: 100%;
        align-self: auto;
        margin: auto;
    }
    section .contentBx .formBx .remember{
        margin: 11px;
    }
    section .imgBx img{
        width: 45%;
    }
}

