 .dashboard {
     background-color: var(--panel-color);
     padding: 10px 14px;
     display: flex;
     flex-direction: column;
    
 }

 /* .top {
     display: flex;
     top: 0;
     justify-content: space-between;
     align-items: center;
     width: 100%;
     height: 10%;
     padding: 10px 14px;
     background-color: var(--dark-color);
     z-index: 3;
 } */

 .top .sidebarToggle {
     font-size: 26px;
     color: var(--text-color);
     cursor: pointer;

 }


 .top .searchBox {
     position: relative;
     height: 35px;
     max-width: 300px;
     width: 100%;
     margin-left: 30px;
     margin-right: 300px;

 }

 .top .searchBox input {
     position: absolute;
     border: 1px solid var(--boarder-color);
     background-color: var(--panel-color);
     padding: 0 25px 0 50px;
     border-radius: 5px;
     height: 100%;
     width: 100%;
     color: var(--text-color);
     font-size: 15px;
     font-weight: 400;
     outline: none;
 }

 .top .searchBox i {
     position: absolute;
     left: 15px;
     font-size: 22px;
     z-index: 2;
     top: 50%;
     transform: translateY(-50%);
     color: var(--text-color);
 }

 .top .toDo {
     font-size: 26px;
     color: var(--text-color);
     cursor: pointer;

 }

 .top .notify {
     font-size: 26px;
     color: var(--text-color);
     cursor: pointer;

 }

 .top .profile {
     color: var(--text-color);
     cursor: pointer;

 }


 /* .top .dropdown {
     float: right;
     overflow: hidden;
 }


 .dropdown .dropbtn {
     font-size: 28px;
     border: none;
     cursor: pointer;
     outline: none;
     color: white;
     padding: 6px 8px;
     background-color: inherit;
     margin: 0;
     border-radius: 10px;
 }


 .dropdown:hover .dropbtn {
     background-color: var(--secondary-color);
 }



 .dropDownPContent {
     display: none;
     position: absolute;
     right: 3%;
     background-color: var(--panel-color);
     min-width: 160px;
     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
     z-index: 100;
 }

 .dropDownPContent a {
     float: none;
     color: var(--text-color);
     padding: 12px 16px;
     text-decoration: none;
     display: block;
     text-align: left;
 }

 .dropDownPContent a:hover {
     background-color: var(--secondary-color);
 }

 .dropdown:hover .dropDownPContent {
     display: block;
 } */

 .dashContent {
     padding-top: 30px; 
     overflow-y: scroll; 
    &::-webkit-scrollbar {
            width: 4px;
            background-color: var(--panel-color);
            
        }
    
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(34, 33, 33, 0.014);
            border-radius: 18px;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: rgba(34, 33, 33, 0.034);
            border-radius: 15px;
        }
     z-index: 3;
 }

.dashContent .title {
    display: flex;
    align-items: center;
    margin: 10px 0 30px 0;

}

.dashContent .title i {
    position: relative;
    height: 35px;
    width: 35px;
    background-color: var(--primary-color);
    border-radius: 6px;
    color: var(--hover-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.dashContent .title .text {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
    margin-left: 10px;
}

.dashContent .boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
   
}

.dashContent .boxes .box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    /* width: calc(100% / 3 - 15px);  */
    padding: 10px 15px;
    /* background-color: var(--hover-color);  */
    transition: var(--tran-05);

}

.boxes  .uil {
    font-size: 30px;
    color: var(--text-color);
}

.boxes  .text {
    white-space: nowrap;
    font-size: 15px;
    font-weight: 400;
    color: var(--text-color);
}

.boxes  .number {
    font-size: 40px;
    font-weight: 400;
    color: var(--text-color);
}

.box-1 {
    background-color: #24c8d1e8;
}

 .box2 {
    background-color: #17c5a5c5;
}

.box3 {
    background-color: #f52c5bd0;
}

.activity .title .textActivities {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    margin-left: 10px;
}

.btnForAll{
    
    height: 45px;
    width: 100px;
    float: right;
    clear: both;
    border: 2px solid black
}

.table th,
  .table td {
    /* Your styles for table headers and cells */
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }

  .actionIcons{
    text-align: center;
  }