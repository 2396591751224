.container {
  margin-top: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.add-task {
  display: flex;
  margin-bottom: 10px;
}

.add-task input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.add-task button {
  margin-left: 10px;
}

.task-list {
  margin-top: 10px;
}

.task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.task-text {
  flex-grow: 1;
  margin-right: 10px;
}

.task button {
  font-size: 14px;
  padding: 4px 8px;
  border: none;
  background-color: #dc3545;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.task button:hover {
  background-color: #c82333;
}