.popup-overlay {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    position: relative;
    max-width: 1000px;
    width: 80%;
    height: auto;
    max-height: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    overflow-y: auto;
  }
  
  .popup-content h2 {
    margin-bottom: 10px;
  }
  
  .popup-content button {
    margin-top: 20px;
  }
  
  .report-card {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .profile-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .profile-column {
    display: flex;
    flex-direction: column;
  }
  
  .profile-info h2 {
    margin-top: 0;
  }
  
  .profile-info p {
    margin: 5px 0;
  }
  
  .school-info {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .school-info img {
    max-width: 100px;
    margin-bottom: 10px;
  }
  
  .school-name {
    font-weight: bold;
    font-size: 20px;
  }
  
  .school-address {
    font-style: italic;
  }
  
  .profile-info h2,
  .course-info h2 {
    margin-top: 0;
  }
  
  .profile-info p,
  .course-info p {
    margin: 5px 0;
  }
  
  .course-info ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .course-info li {
    margin-bottom: 5px;
  }